<!-- <app-navbar></app-navbar>
<div class="main d-flex align-items-center w-100">
    <div class="overlay w-100">
        <div class="main2">

            <div class="mt-10 d-flex flex-column">
                <h3 class="custom-text">Soutenons les couches</h3>
                <span class="custom-text mt-5"> vulnérables</span>
            </div>

            <div class="">

                <div class="mt-3 d-flex justify-content-center flex-wrap pape" *ngIf="initiatives.length > 0">
                    <div class="" *ngFor="let initiative of initiatives">
                        <a [routerLink]="['/initiative', initiative.id]" class="" (click)="selectCard(initiative.id)">
                            <div class="card text-center d-flex justify-content-center align-items-center p-3 w-100"
                                [ngStyle]="{
                                'background-color': selectedCard === 0 ? '#009640' : '#ffffff',
                                'color': selectedCard === 0 ? '#ffffff' : '#009640'
                              }">
                                <img [src]="initiative.imgUrl" alt="{{ initiative.name }}" width="100">


                                <p>{{ initiative.name }}</p>

                                <span class="mt-3" innerHTML="{{ initiative.shortDescription
                                }}">
                                </span>
                            </div>
                        </a>
                    </div>
                    <div class="">
                        <a [routerLink]="" class="">
                            <div class="card text-center d-flex justify-content-center align-items-center p-3 w-100"
                                [ngStyle]="{
                                'background-color': selectedCard === 0 ? '#009640' : '#ffffff',
                                'color': selectedCard === 0 ? '#ffffff' : '#009640'
                              }">
                                <img src="..//../../assets/images/autre.png" width="100">


                                <p>don pour les mutuells</p>

                                <span class="mt-3" > selectionner un mutuelle
                                </span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div style="width: 50%;">

            </div>
        </div>

    </div>
</div>

<app-footer></app-footer> -->

<app-navbar></app-navbar>
<div class="main d-flex align-items-center w-100">
    <div class="overlay w-100">
        <div class="main2">

            <div class="mt-10 d-flex flex-column">
                <h3 class="custom-text">Soutenons les couches</h3>
                <span class="custom-text mt-5"> vulnérables</span>
            </div>

            <div class="">

                <div class="mt-3 d-flex justify-content-center flex-wrap pape" *ngIf="initiatives.length > 0">
                    <div class="" *ngFor="let initiative of initiatives">
                        <a [routerLink]="['/initiative', initiative.id]" class="" (click)="selectCard(initiative.id)">
                            <div class="card text-center d-flex justify-content-center align-items-center p-3 w-100"
                                [ngStyle]="{
                                'background-color': selectedCard === initiative.id ? '#009640' : '#ffffff',
                                'color': selectedCard === initiative.id ? '#ffffff' : '#009640'
                              }">
                                <img [src]="initiative.imgUrl" alt="{{ initiative.name }}" width="100">

                                <p>{{ initiative.name }}</p>

                                <span class="mt-3" innerHTML="{{ initiative.shortDescription }}">
                                </span>
                            </div>
                        </a>
                    </div>

                    <!-- Static Card for Mutuelles Donation -->
                    <!-- <div class="">
                        <a [routerLink]="['/mutuelle-donation']" class="">
                            <div class="card text-center d-flex justify-content-center align-items-center p-3 w-100"
                               >
                                <img src="../../assets/images/autre.png" width="100">
                                <p class="text-primary mt-5">Don pour les mutuelles</p>
                                
                            </div>
                        </a>
                    </div> -->

                </div>
            </div>
            <div style="width: 50%;"></div>
        </div>
    </div>
</div>
<app-footer></app-footer>
