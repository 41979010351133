<!-- <app-navbar></app-navbar>
<div class="main d-flex justify-content-center align-items-center">
  <div class="container d-flex flex-column justify-content-center w-100">
    <h2 class="text-center text-primary">Choix de la mutuelle</h2>
    <h4 for="region" class="text-primary">Veuillez sélectionner la région du parrainage</h4>
    <ng-select [clearable]="false" class="test" id="region" [(ngModel)]="selectedRegion">
      <ng-option *ngFor="let region of regions" [value]="region">{{ region.libelle }}</ng-option>
    </ng-select>
    <div *ngIf="selectedRegion">
      <h3 class="text-primary mt-8">Veuillez sélectionner une mutuelle</h3>
      <div class="w-100">
        <li *ngFor="let mutuelle of mutuellesParRegion[selectedRegion]" class="form-control mt-3 w-100">
          <label class="d-flex align-items-center justify-content-between">
            <span>{{ mutuelle.name }}</span>
            <div class="form-check form-check-custom form-check-solid form-check-lg">
              <input class="form-check-input" type="radio" name="selectedMutuelle" [value]="mutuelle.id"
                [(ngModel)]="selectedMutuelleId" id="flexCheckboxLg" />
            </div>
          </label>
        </li>
      </div>
    </div>
    <div class="d-flex mt-4">
      <button class="btn btn-primary" (click)="passerAuPaiement()">Passer au paiement</button>
    </div>
  </div>
</div>
<app-footer></app-footer> -->

<app-navbar></app-navbar>
<div class="main d-flex justify-content-center align-items-center">
  <div class="container d-flex flex-column justify-content-center w-100">
    <h2 class="text-center text-primary">Choix de la localité</h2>
    <h4 class="text-primary">Veuillez sélectionner la région du parrainage</h4>
    <ng-select [clearable]="false" class="test" id="region" [(ngModel)]="selectedRegion" (change)="onRegionSelect()">
      <ng-option *ngFor="let region of regions" [value]="region">{{ region.libelle }}</ng-option>
    </ng-select>
    <div *ngIf="selectedRegion">
      <h3 class="text-primary mt-8">Veuillez sélectionner un département</h3>
      <div class="w-100">
        <li *ngFor="let mutuelle of mutuelles" class="form-control mt-3 w-100">
          <label class="d-flex align-items-center justify-content-between">
            <span>{{ mutuelle.libelle.replace('Mutuelle de Santé Départementale de','').replace('Mutuelle de Santé Departementale de ','') }}</span>
            <div class="form-check form-check-custom form-check-solid form-check-lg">
              <input class="form-check-input" type="radio" name="selectedMutuelle" [value]="mutuelle.id"
                [(ngModel)]="selectedMutuelleId" />
            </div>
          </label>
        </li>
      </div>
    </div>
    <div class="d-flex mt-4">
      <!-- <button type="button" class="btn btn-primary me-5" (click)="goToPreviousStep()"> -->
      <a type="button" class="btn btn-primary me-5" href="./sponshor">
        <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.7832 9L1.04094 5.0315L4.7832 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        Précédent
      </a>
      <button class="btn btn-primary" (click)="passerAuPaiement()">Passer au paiement</button>
    </div>
  </div>
</div>
<app-footer></app-footer>



