<div class="main d-flex align-items-center w-100 overlay" id="accueil">
    <div class=""></div> 
    <div class="container-lg px-lg-0">
        <div class="row flex-column-reverse px-4 px-md-2 px-lg-0 flex-md-row d-flex align-items-center justify-content-between w-100" style="margin-top: 70px;">
            <div class="col-md-7 d-flex flex-column gap-4 mt-md-4"> 
                <h2 class="title">COUVERTURE MALADIE POUR VOS PROCHES PARTOUT AU SENEGAL</h2>
                <p class="text">
                    Pour faciliter le paiement et la collecte des cotisations, l'Agence Sénégalaise pour la Couverture Sanitaire Universelle (SEN-CSU) a mis en ligne la plateforme SunuCSU. Cette plateforme SunuCSU répond aux besoins des assurés et des structures de santé, tout en modernisant le système de gestion de santé et favoriser l'accès aux soins de qualité. Les utilisateurs du SunuCSU peuvent également contribuer de manière participative aux programmes de la CSU par les dons.
                </p>
              
                <div class="buttons w-100 d-flex"> 
                    <button class="button" (click)="openPopup()" style="margin-left: 0;">ADHÉRER À LA CSU</button>
                    <button class="button" routerLink="/initiative" >FAIRE UN DON</button>
                    <button class="button" routerLink="/sponshor" >PARRAINAGE</button>
                  </div>
                  
                  <app-insurance-status-popup *ngIf="isPopupVisible" (close)="closePopup()"></app-insurance-status-popup>
  
            </div>
            <div class="col-md-5 px-0 ps-2" style="position: relative;"> 
                <div class="container" >    
                    <img src="../../../assets/images/Frame (1).png" alt="hero" >
                </div>
            </div>
            <div id="about"></div>
        </div>
    </div>
</div>
