<div id="kt_app_content" class="app-content  flex-column-fluid">

    <div class="row g-5 g-xl-10 mb-5">
        <div class="row">
            <!--begin::Col-->
            <div class="col-xl-6">

                <!--begin::Card widget 19-->
                <div class="card card-flush h-lg-100">
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                            [colors]="chartOptions.theme.palette">
                        </apx-chart>
                    </div>

                </div>
                <!--end::Card widget 19-->
            </div>
            <!--end::Col-->

            <!--begin::Col-->
            <div class="col-xl-6">
                <!--begin::Engage widget 9-->
                <div class="card card-flush h-lg-100">
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                            [colors]="chartOptions.theme.palette">
                        </apx-chart>
                    </div>

                </div>
                <!--end::Engage widget 9-->

            </div>
            <!--end::Col-->

              <!--begin::Col-->
              <div class="col-xl-6">
                <!--begin::Engage widget 9-->
                <div class="card card-flush h-lg-100">
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                            [colors]="chartOptions.theme.palette">
                        </apx-chart>
                    </div>

                </div>
                <!--end::Engage widget 9-->

            </div>
            <!--end::Col-->
        </div>
    </div>

    <div class="row g-5">
        <div class="row g-5">
            <!--begin::Col-->
            <div class="col-xl-6">

                <!--begin::Card widget 19-->
                <div class="card card-flush h-lg-100">
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                            [colors]="chartOptions.theme.palette">
                        </apx-chart>
                    </div>

                </div>
                <!--end::Card widget 19-->
            </div>
            <!--end::Col-->

            <!--begin::Col-->
            <div class="col-xl-6">
                <!--begin::Engage widget 9-->
                <div class="card card-flush h-lg-100">
                    <div id="chart">
                        <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart"
                            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"
                            [colors]="chartOptions.theme.palette">
                        </apx-chart>
                    </div>

                </div>
                <!--end::Engage widget 9-->

            </div>
            <!--end::Col-->
        </div>
    </div>
    <div class="card mt-10 ">
        <div class="card-body pt-0">
            <div id="kt_customers_table_wrapper" class="dt-container dt-bootstrap5 dt-empty-footer">
                <div class="table-responsive ">
                    <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable w-100" id="kt_customers_table">
                        <thead class="w-100">
                            <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 w-100">
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-50 ms-2">
                                    <span class="dt-column-title text-center text-white ms-4">initiative de gratuité</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-50">
                                    <span class="dt-column-title text-center text-white">montant de mes initiatives(cfa)</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-100">
                                    <span class="dt-column-title text-center text-white">montant total collecté(cfa)</span>
                                </th>
                                <th class="min-w-125px dt-orderable-asc dt-orderable-desc bg-primary w-50">
                                    <span class="dt-column-title text-center text-white">Poids de mes dons (%)</span>
                                </th>
                    
                            </tr>
                        </thead>
                        <tbody class="fw-semibold text-gray-600 bg-white">
                            <tr *ngFor="let donation of donations">
                                <td><span class="ms-4">{{ donation.initiative.name  || donation.mutual.basicInfo.libelle || '-' }}</span></td>
                                <td>{{ donation.amount || '-' }} Cfa</td>
                                <td>{{ donation.initiative.amountCollected || '-' }} Cfa</td>
                                <td>{{donation.initiative.averageCostCase || '-'
                                }}</td>
                                 
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="row">
                    <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                        <span class="text-muted">Total: {{ totalItems }}</span>
                    </div>
                    <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-10">
                      <pagination-controls 
                          previousLabel="" 
                          nextLabel="" 
                          (pageChange)="paginate($event)">
                      </pagination-controls>
                  </div>
                  
                </div> -->
            </div>
        </div>
    </div>
</div>