import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InitiativeService } from '../service/initiative.service';
import { Initiative } from '../model/initiative.model';
import { Donation } from '../model/donation.model';
import { DonationService } from '../donation.service';

@Component({
  selector: 'app-initiative-detail',
  templateUrl: './initiative-detail.component.html',
  styleUrls: ['./initiative-detail.component.scss']
})
export class InitiativeDetailComponent implements OnInit {
  @Input() initiativeId!: string;
  initiative: Initiative | null = null;
  donationAmount: number = 0;
  constructor(
    private initiativeService: InitiativeService,
    private donationService: DonationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.initiativeId) {
      this.getInitiativeDetails(this.initiativeId);
    } else {
      this.route.params.subscribe(params => {
        this.initiativeId = params['id'];
        if (this.initiativeId) {
          this.getInitiativeDetails(this.initiativeId);
        } else {
          console.error('No initiative ID provided');
        }
      });
    }
  }

  getInitiativeDetails(id: string): void {
    this.initiativeService.getInitiativeById(id).subscribe(
      (data: Initiative) => {
        this.initiative = data;
      },
      error => {
        console.error('Error fetching initiative:', error);
      }
    );
  }

  createDonationAndNavigate(): void {
    if (!this.donationAmount || this.donationAmount <= 0) {
      return;
    }

    const donation: Donation = {
      id: 0, 
      amount: this.donationAmount,
      initiative: {
        id: parseInt(this.initiativeId, 10)
      }
    };

    this.donationService.createDonation(donation).subscribe(
      (createdDonation: Donation) => {
        this.router.navigate(['/payment-initiative'], {
          queryParams: {
            donationId: createdDonation.id,
            initiativeId: this.initiativeId,
            amount: this.donationAmount,
          }
        });
      },
      error => {
        console.error('Error creating donation:', error);
        if (this.donationAmount < 5){
          alert("Le montant doit être supérieur ou égale à 5 FCFA")
        }
      }
    );
  }
}
